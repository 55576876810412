<template>
  <div
    class="pwdchange container van-tab__panel"
    :style="{ backgroundImage: 'url(img/pro-bg.png)' }"
  >
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <div class="txt-left con">
          <van-icon v-if="type === 'login'" name="lock" />
          <van-icon v-else name="card" />
          <span style="margin-left: 10px">{{
            type === "login" ? $t("menus.password5") : $t("menus.password4")
          }}</span>
        </div>
        <van-field
          v-model="form.oldpassword"
          v-if="!isOff"
          type="password"
          :label="$t('menus.password6')"
          :label-width="locale === 'en' ? '100px' : '75px'"
          :placeholder="$t('public.input') + $t('menus.password6')"
          class="mt-10"
        />
        <van-field
          v-model="form.newpassword"
          type="password"
          :label="$t('menus.password7')"
          :label-width="locale === 'en' ? '100px' : '75px'"
          :placeholder="$t('public.input') + $t('menus.password7')"
          class="mt-10"
        />
        <van-field
          v-model="form.newpassword2"
          type="password"
          :label="$t('menus.password8')"
          :label-width="locale === 'en' ? '150px' : '75px'"
          :placeholder="$t('public.input') + $t('menus.password8')"
          class="mt-10"
        />
        <p>&nbsp;</p>
      </van-cell-group>
      <div style="margin-top: 16px">
        <van-button round block type="primary" native-type="submit">
          {{ $t("menus.submit") }}
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script setup>
import { reactive, toRaw } from "vue";
import { Toast } from "vant";
import { useRouter } from "vue-router";
import { setLoginPwd, setSecurityPwd } from "@/api/user";
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const router = useRouter();
const type = router.currentRoute.value.query.type;
const isOff = router.currentRoute.value.query.isOff;
const form = reactive({
  oldpassword: "",
  newpassword: "",
  newpassword2: "",
});
const onSubmit = () => {
  if (
    (form.oldpassword == "" && !isOff) ||
    form.newpassword == "" ||
    form.newpassword2 == ""
  ) {
    Toast.fail(t("public.fill"));
    return;
  }
  if (form.newpassword !== form.newpassword2) {
    Toast.fail(t("public.inconsistent"));
    return;
  }
  const fn = type === "login" ? setLoginPwd : setSecurityPwd;
  const toastLoading = Toast.loading({
    duration: 0,
    forbidClick: true,
  });
  fn(toRaw(form))
    .then(() => {
      Toast.success(t("public.success"));
      setTimeout(() => {
        router.push("/my");
      }, 1500);
    })
    .catch(() => {
      toastLoading.clear();
    });
};
</script>

<style lang="less" scoped>
.pwdchange {
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: 0 -50px;
  color: #ddd;

  .con {
    font-size: 16px;
    margin: 10px;
  }

  .card {
    display: flex;
    justify-content: left;
    /* 水平居中 */
    align-items: center;
    justify-content: space-between;
    font-size: 18px;

    i:first-child {
      font-size: 40px;
      margin-right: 20px;
    }

    span {
      width: 80%;
    }
  }

  .van-cell-group--inset {
    margin: 10px 0;
  }
}
</style>
